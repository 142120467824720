<template>
  <input  v-if="mask"
     :type="type"
     v-mask="mask"
     class="input_component"
     :readonly="readonly"
     :class="small ? 'input_component--small' : '', icon ? 'input_component--icon' : ''"
     :placeholder="placeholder"
     @input="changeValue"
    :max="max"
     :value="value">
  <input v-else
     :type="type"
     class="input_component"
     :readonly="readonly"
     :class="small ? 'input_component--small' : '', icon ? 'input_component--icon' : ''"
     :placeholder="placeholder"
     @input="changeValue"
     :value="value">
</template>

<script>
import { reactive, ref, computed } from 'vue'
import {mask} from 'vue-the-mask'

export default {
  directives: {mask},
  props:{
    placeholder: String,
    small: Boolean,
    mask: String,
    icon: Boolean,
    value: String,
    readonly: Boolean,
    type: {
      type: String,
      default: 'text'
    },
    max: Date|Number
  },
  emits: ['input'],

  setup(props, { emit }) {
    let changeValue = (event) => {
      emit('input', event.target.value);
    }
    return {
      changeValue
    }
  },
}
</script>

<style lang="scss">
@import "../../../src/assets/scss/utils/vars";
</style>
