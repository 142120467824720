<template>
  <div  class="select_dropdown"
        :class="small ? 'select_dropdown--small' : ''"
        @click="!readonly ? isOpened=!isOpened : ''"
        v-click-outside="closeDropdown"
  >
    <input v-if="listIsObject" type="text"
           class="input_component select_dropdown__input"
           :class="readonly ? 'select_dropdown__input--readonly' :''"
           name="reason"
           readonly
           :placeholder="placeholder"
           :value="selected?.name">
    <input v-else type="text"
           class="input_component select_dropdown__input"
           :class="readonly ? 'select_dropdown__input--readonly' :''"
           name="reason"
           readonly
           :placeholder="placeholder"
           :value="selected">
    <div class="select_dropdown__icon"
         :class="isOpened ? 'select_dropdown__icon--opened':''">
      <svg-icon  name="solid/chevron-down"/>
    </div>
    <div v-show="isOpened" class="select_dropdown__list">
      <div v-if="listIsObject && type=='id'" v-for="item in list"
           @click="changeSelect(item)"
           class="select_dropdown__list__item"
           :class="item.id === selected?.id ? 'select_dropdown__list__item--selected' : ''">
        {{item.name}}
      </div>
      <div v-else-if="listIsObject" v-for="item in list"
           @click="changeSelect(item)"
           class="select_dropdown__list__item"
           :class="item.state_id === selected?.state_id ? 'select_dropdown__list__item--selected' : ''">
        {{item.name}}
      </div>
      <div v-else v-for="item in list"
           @click="changeSelect(item)"
           class="select_dropdown__list__item"
           :class="item === selected ? 'select_dropdown__list__item--selected' : ''">
        {{item}}
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import vClickOutside from 'click-outside-vue3'
export default {
name: "SelectDropdown",
  components: {
  },
  props: ['list', 'placeholder', 'small', 'selected','readonly', 'listIsObject', 'type'],
  emits: ['select'],
  directives: {
    clickOutside: vClickOutside.directive
  },
  setup(props, { emit }) {
    let isOpened = ref(false)
    let changeSelect = (item) => {
      emit('select', item);
    }
    let closeDropdown = () => {
      isOpened.value = false
    }
    return {
      changeSelect,
      isOpened,
      closeDropdown
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/utils/vars";
.select_dropdown {
  position: relative;
  cursor: pointer;
  z-index: 3;
  &__input {
    height: 50px;
    display: flex;
    align-items: center;
    padding-right: 48px;
    padding-left: 20px;
    cursor: pointer;
    background: white!important;
    &--readonly {
      background: linear-gradient(0deg, rgba(11, 53, 83, 0.05), rgba(11, 53, 83, 0.05)), #FFFFFF!important;
    }
    &:hover {
      border: 1px solid rgba(11, 53, 83, 0.4)!important;
    }
    &:focus {
      border: 1px solid rgba(11, 53, 83, 0.4)!important;
    }
  }
  &__icon {
    position: absolute;
    right: 20px;
    font-size: 18px;
    top: 12px;
    color: #1C2833;
    transition-duration: 0.2s;
    &--opened {
      transform: rotate(180deg);
    }
  }
  &__list {
    top: 50px;
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(28, 40, 51, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(28, 40, 51, 0.1);
    display: flex;
    flex-direction: column;

      &__item {
        width: 100%;
        padding: 16px 20px;
        transition-duration: 0.2s;
        font-weight: bold;
        font-size: 15px;
        line-height: 100%;
        background: #FFFFFF;
        &:hover {
          background: rgba(11, 53, 83, 0.1)
        }
        &--selected {
          background: rgba(11, 53, 83, 0.1)
        }
      }
  }
  &--small {
    .select_dropdown {
      position: relative;
      cursor: pointer;
      &__input {
        padding-right: 32px;
        padding-left: 12px;
        height: 40px;
      }
      &__icon {
        right: 16px;
        font-size: 14px;
        top: 10px;
        color: rgba(28, 40, 51, 0.8);
      }
      &__list {
        top: 40px;
        z-index: 5;
        &__item {
          padding: 10px;
          transition-duration: 0.2s;
          font-weight: normal;
          font-size: 15px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .select_dropdown {
    position: relative;
    cursor: pointer;
    &__input {
      padding-right: 32px;
      padding-left: 12px;
      height: 40px;
      font-size: 13px;
    }
    &__icon {
      right: 16px;
      font-size: 14px;
      top: 10px;
    }
    &__list {
      top: 40px;
      z-index: 5;
      &__item {
        width: 100%;
        padding: 10px 12px;
        font-size: 13px;
      }
    }
  }
}
</style>
