<template>
  <div class="preloader__img small">
    <svg-icon name="solid/spinner"></svg-icon>
  </div>
</template>

<script>
  export default {
    name: "PreloaderSmall",
  }
</script>

<style scoped lang="scss">
.preloader__img.small ::v-deep span svg{
  color: rgba(11, 53, 83, 0.6)
}
</style>
